import React, { useEffect, useState } from 'react'
import { Empty, Input, Modal, Spin, Switch, message } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { BiPencil, BiPlusCircle, BiTrash } from 'react-icons/bi';
import { BaseServices } from '../../../helpers/baseServices';
import { global_variables } from '../../../helpers/globalVariables';
import { GetSignedUrl } from '../../../functions/getSignedAudioUrl';
import { DoSignedAudioUpload } from '../../../functions/doSignedAudioUpload';
import BookCategorySelector from '../bookCategorySelector';
import CoverImageUploader from '../../mediaUploader/coverImageUploader';
import BannerImageUploader from '../../mediaUploader/bannerImageUploader';
import SampleAudioComponent from '../sampleAudioComponent';
import AudioSampleUploader from '../../mediaUploader/audioSampleUploader';
import SingleChapterComponent from './singleChapterComponent';
import AddStoryChapter from './addStoryChapter';
import LanguageSelector from '../languageSelector';



interface modalProps {
    isOpened: boolean
    handleClose: () => void
    tempBookData: any
    handleReFetch: () => void
}

const EditExistingStory = ({ isOpened, handleClose, tempBookData, handleReFetch }: modalProps) => {

    const [isPublishing, setIsPublishing] = useState(false)
    const [savingDraft, setSavingDraft] = useState(false)
    const [fetchingBookChapters, setFetchingBookChapters] = useState(false)
    const [bookChapters, setBookChapters] = useState([])
    const [reFetchChapters, setReFetchChapters] = useState(false)

    const [isFetching, setIsFetching] = useState(true)
    const [bookData, setBookData] = useState<any>(null)
    const [theTitle, setTheTitle] = useState("")
    const [theAuthor, setTheAuthor] = useState("")
    const [authNotes, setAuthNotes] = useState("")
    const [narrator, setNarrator] = useState("")
    const [theAbout, setTheAbout] = useState("")
    const [bookPrice, setBookPrice] = useState<any>(0)
    const [bookCategories, setBookCategories] = useState<any>([])
    const [bookLanguage, setBookLanguage] = useState("")
    //audio
    const [selectedAudioFile, setSelectedAudioFile] = useState<File | null>(null);
    const [audioUrl, setAudioUrl] = useState<any>(null);
    const [audioFileName, setAudioFileName] = useState<any>("")
    const [editAudio, setEditAudio] = useState<boolean | any>(false)

    //edit
    const [editCover, setEditCover] = useState<boolean | any>(false)
    const [editBanner, setEditBanner] = useState<boolean | any>(false)
    const [bookCoverFile, setBookCoverFile] = useState<File | null>(null);
    const [bookCoverUrl, setBookCoverUrl] = useState<string | null>(null);
    const [bookBannerFile, setBookBannerFile] = useState<File | null>(null);
    const [bookBannerUrl, setBookBannerUrl] = useState<string | null>(null);


    //openAddChapter
    const [openAddChapter, setOpenAddChapter] = useState(false)
    const handleOpenAddChapter = () => {
        setOpenAddChapter(true)
    }
    const handleCloseAddChapter = () => {
        setOpenAddChapter(false)
    }

    //onMount
    useEffect(() => {
        if (tempBookData) {
            getSingleAudioBook()
            getBookChapters();
        }

        // eslint-disable-next-line
    }, [reFetchChapters])

    //getBookChapters
    const getBookChapters = async () => {
        setFetchingBookChapters(true)
        try {
            const response = await BaseServices.get(`${global_variables.retrieve_single_book}/${tempBookData?.id}/chapter/list/`)
            console.log("---bookChapters:", response?.data)
            setBookChapters(response?.data)
            setFetchingBookChapters(false)
        } catch (error) {
            console.log(error)
        }
    }

    //getSingleAudioBook
    const getSingleAudioBook = async () => {
        setIsFetching(true)
        try {
            const response = await BaseServices.get(`${global_variables.retrieve_single_book}/${tempBookData?.id}/retrieve`)
            setBookData(response?.data)
            console.log("singleInfo:", response?.data)
            setTheTitle(response?.data?.title)
            setTheAuthor(response?.data?.author)
            setAuthNotes(response?.data?.author_notes)
            setNarrator(response?.data?.narrated_by)
            setTheAbout(response?.data?.about)
            setBookPrice(response?.data?.price)
            const tempCats: any = []
            response?.data?.categories.map((item: any) => tempCats.push(item?.id))
            setBookCategories(tempCats)
            setAudioUrl(response?.data?.audio_sample)
            setIsFetching(false)
        } catch (error) {
            console.log(error)
        }
    }

    //handleAudioFileChange
    const handleAudioFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setSelectedAudioFile(file); console.log("audioExtension:", file)
            setAudioFileName(file?.name)
            const url = URL.createObjectURL(file);
            setAudioUrl(url);
            setEditAudio(true)
        }
    };

    //handlePublish
    const handlePublish = () => {
        let data = new FormData();
        data.append('title', theTitle);
        data.append('author', theAuthor);
        data.append('about', theAbout);
        data.append('narrated_by', narrator);
        data.append('author_notes', authNotes);
        data.append('type', bookData?.type);
        data.append('status', 'Published');
        data.append('language_id', bookLanguage);
        data.append('category_ids', bookCategories);
        data.append('price', Number(bookPrice).toFixed(2));
        bookBannerFile && data.append('banner', bookBannerFile);
        editBanner && data.append('edit_banner', editBanner);
        bookCoverFile && data.append('media', bookCoverFile);
        editCover && data.append('edit_image', editCover);

        setIsPublishing(true)

        //if audio was changed
        if (editAudio) {
            //get audio upload url
            console.log("audio changed")
            GetSignedUrl(audioFileName, "audiobook_sample")
                .then((signedRes) => {
                    console.log("signedRes:", signedRes)
                    //signed upload
                    DoSignedAudioUpload(signedRes?.upload_url, selectedAudioFile, signedRes?.content_type)
                        .then((uploadRes: any) => {
                            console.log("uploadRes:", uploadRes)
                            if (uploadRes?.status === 200) {
                                data.append('audio_sample', signedRes?.media_url);
                                data.append('edit_audio', editAudio);

                                //update actual book
                                BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
                                    .then((response) => {
                                        console.log("update_story_success:", response)
                                        message.success("Story was published successfully!")
                                        setIsPublishing(false)
                                        handleClose()
                                        handleReFetch()
                                    })
                                    .catch((error) => {
                                        setIsPublishing(false)
                                        console.log("update_story_error:", error)
                                        message.error(error?.detail || error?.response?.data?.detail || "An error occured, please try again")
                                    });

                            } else {
                                setIsPublishing(false)
                                message.error("Audio upload failed, please restart the process.")
                            }
                        })
                        .catch((uploadErr) => {
                            //signed upload err
                            setIsPublishing(false)
                            console.log("uploadErr:", uploadErr)
                            message.error(uploadErr?.details || uploadErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                        })
                }).catch((signedErr) => {
                    //get audio upload url error
                    setIsPublishing(false)
                    console.log("signedErr:", signedErr)
                    message.error(signedErr?.details || signedErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                })
        }
        else {
            //audio was not changed
            console.log("audio not changed")
            BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
                .then((response) => {
                    console.log("update_story_success:", response)
                    message.success("Story was saved as draft!")
                    setIsPublishing(false)
                    handleClose()
                    handleReFetch()
                })
                .catch((error) => {
                    setIsPublishing(false)
                    console.log("update_story_error:", error)
                    message.error(error?.detail || error?.response?.data?.detail || "An error occured, please try again")
                });
        }
    }

    //handleSaveAsDraft
    const handleSaveAsDraft = () => {
        let data = new FormData();
        data.append('title', theTitle);
        data.append('author', theAuthor);
        data.append('about', theAbout);
        data.append('narrated_by', narrator);
        data.append('author_notes', authNotes);
        data.append('type', bookData?.type);
        data.append('status', 'Draft');
        data.append('language_id', bookLanguage);
        data.append('category_ids', bookCategories);
        data.append('price', Number(bookPrice).toFixed(2));
        bookBannerFile && data.append('banner', bookBannerFile);
        editBanner && data.append('edit_banner', editBanner);
        bookCoverFile && data.append('media', bookCoverFile);
        editCover && data.append('edit_image', editCover);


        setSavingDraft(true)

        //if audio was changed
        if (editAudio) {
            //get audio upload url
            console.log("audio changed")
            GetSignedUrl(audioFileName, "audiobook_sample")
                .then((signedRes) => {
                    console.log("signedRes:", signedRes)
                    //signed upload
                    DoSignedAudioUpload(signedRes?.upload_url, selectedAudioFile, signedRes?.content_type)
                        .then((uploadRes: any) => {
                            console.log("uploadRes:", uploadRes)
                            if (uploadRes?.status === 200) {
                                data.append('audio_sample', signedRes?.media_url);
                                data.append('edit_audio', editAudio);

                                //update actual book
                                BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
                                    .then((response) => {
                                        console.log("update_story_success:", response)
                                        message.success("Story was saved as draft!")
                                        setSavingDraft(false)
                                        handleClose()
                                        handleReFetch()
                                    })
                                    .catch((error) => {
                                        setSavingDraft(false)
                                        console.log("update_story_error:", error)
                                        message.error(error?.detail || error?.response?.data?.detail || "An error occured, please try again")
                                    });

                            } else {
                                setSavingDraft(false)
                                message.error("Audio upload failed, please restart the process.")
                            }
                        })
                        .catch((uploadErr) => {
                            //signed upload err
                            setSavingDraft(false)
                            console.log("uploadErr:", uploadErr)
                            message.error(uploadErr?.details || uploadErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                        })
                }).catch((signedErr) => {
                    //get audio upload url error
                    setSavingDraft(false)
                    console.log("signedErr:", signedErr)
                    message.error(signedErr?.details || signedErr?.response?.data?.error || "An error occured while uploading audio, please try again")
                })
        }
        else {
            //audio was not changed
            console.log("audio not changed")
            BaseServices.patch(`${global_variables.retrieve_single_book}/${tempBookData?.id}/update/`, data)
                .then((response) => {
                    console.log("update_story_success:", response)
                    message.success("Story was saved as draft!")
                    setSavingDraft(false)
                    handleClose()
                    handleReFetch()
                })
                .catch((error) => {
                    setSavingDraft(false)
                    console.log("update_story_error:", error)
                    message.error(error?.detail || error?.response?.data?.detail || "An error occured, please try again")
                });
        }

    }


    //handleBookCoverSelect
    const handleBookCoverSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setBookCoverFile(file);
            const url = URL.createObjectURL(file);
            setBookCoverUrl(url);
        }
    };

    //handleBookBannerSelect
    const handleBookBannerSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setBookBannerFile(file);
            const url = URL.createObjectURL(file);
            setBookBannerUrl(url);
        }
    };

    return (
        <>
            <Modal width={1000} open={isOpened} onCancel={handleClose} footer={null} maskClosable={false} keyboard={false}>
                <Spin spinning={isFetching}>
                    <p className='text-[26px] font-[700]'>Edit A Story / Book</p>

                    <div className='text-black text-opacity-[80%] my-5 px-5 lg:grid lg:grid-cols-2 w-full gap-[100px]'>
                        {/* left */}
                        <div className="w-full">
                            <div>
                                <label htmlFor='title' className='text-[16px] font-[700] pl-2'>Title</label>
                                <Input id="title" className={`w-full h-[40px] rounded-[10px] mt-[5px]`} value={theTitle} onChange={(e) => setTheTitle(e.target.value)} />
                            </div>

                            <div className='mt-[15px]'>
                                <label htmlFor='author' className='text-[16px] font-[700]'>Author</label>
                                <Input id="author" className={`w-full h-[40px] rounded-[10px] mt-[5px]`} value={theAuthor} onChange={(e) => setTheAuthor(e.target.value)} />
                            </div>

                            <div className='mt-[15px]'>
                                <label htmlFor='notes' className='text-[16px] font-[700]'>Author Notes</label>
                                <Input.TextArea id="notes" className={`w-full rounded-[10px] mt-[5px]`} style={{ height: '150px' }} value={authNotes} onChange={(e) => setAuthNotes(e.target.value)} />
                            </div>

                            <div className='mt-[15px]'>
                                <label htmlFor='narrator' className='text-[16px] font-[700]'>Narated / Performed By</label>
                                <Input id="narrator" className={`w-full h-[40px] rounded-[10px] mt-[5px]`} value={narrator} onChange={(e) => setNarrator(e.target.value)} />
                            </div>

                            <div className='mt-[15px]'>
                                <label htmlFor='about' className='text-[16px] font-[700]'>About the Book / Play</label>
                                <Input.TextArea id="about" className={`w-full rounded-[10px] mt-[5px]`} style={{ height: '150px' }} value={theAbout} onChange={(e) => setTheAbout(e.target.value)} />
                            </div>

                            {
                                bookData?.categories &&
                                <div className='mt-[15px]'>
                                    <p className='text-[16px] font-[700]'>Category</p>
                                    <div className='w-full'>
                                        <div className="w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]">
                                            <BookCategorySelector onChange={(selected) => setBookCategories(selected)} defaultValue={bookCategories} />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="mt-5">
                                <label htmlFor='language' className='text-[16px] font-[400]'>Language</label>
                                <div className='w-full h-[52px] rounded-[20px] mt-[7px] border-[1px] border-black border-opacity-[20%]'>
                                    <LanguageSelector defualtValue={tempBookData?.language?.id} onChange={(selected: any) => setBookLanguage(selected)} />
                                </div>
                            </div>

                            <div className="mt-5">
                                <label htmlFor='price' className='text-[16px] font-[400]'>Price</label>
                                <Input id="price" className={`w-full h-[52px] rounded-[20px] mt-[7px]`} value={bookPrice} onChange={(e) => setBookPrice(e.target.value)} />
                            </div>

                            {/* cover image */}
                            <div className='mt-[20px]'>
                                <p className='text-[16px] font-[700]'>Cover Image</p>
                                {
                                    editCover ?
                                        <div className="mt-[6px]">
                                            <CoverImageUploader onChange={handleBookCoverSelect} placeholder='Select file to upload' />
                                            <div className='flex justify-between mt-2'>
                                                {bookCoverFile && <p>Selected File: {bookCoverFile.name}</p>}
                                                {bookCoverUrl && <img className='h-[100px] w-[100px] object-contain border border-gray-500 rounded' src={bookCoverUrl} alt="Uploaded Cover" />}
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-[6px] flex gap-3'>
                                            <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={bookData?.cover_image || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />

                                            <div>
                                                <button className='h-10 w-10 bg-gray-300 rounded-[5px] flex justify-center items-center' onClick={() => setEditCover(true)}>
                                                    <BiPencil className='h-5 w-5' />
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>

                            {/* banner image */}
                            <div className='mt-[15px]'>
                                <p className='text-[16px] font-[700]'>Banner Image</p>
                                {
                                    editBanner ?
                                        <div className="mt-[6px]">
                                            <BannerImageUploader onChange={handleBookBannerSelect} placeholder='Select file to upload' />
                                            <div className='flex justify-between mt-2'>
                                                {bookBannerFile && <p>Selected File: {bookBannerFile.name}</p>}
                                                {bookBannerUrl && <img className='h-[100px] w-[100px] object-contain border border-gray-500 rounded' src={bookBannerUrl} alt="Uploaded Banner" />}
                                            </div>
                                        </div>
                                        :
                                        <div className='mt-[6px] flex gap-3'>
                                            <img className='h-[96px] w-[96px] rounded-[8px] object-cover' src={bookData?.banner || "https://tribe-s3-production.imgix.net/C5yUOy3RzAZV9mFvgXoq5?auto=compress,format&dl"} alt="" />

                                            <div>
                                                <button className='h-10 w-10 bg-gray-300 rounded-[5px] flex justify-center items-center' onClick={() => setEditBanner(true)}>
                                                    <BiPencil className='h-5 w-5' />
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className='mt-[15px]'>
                                <p className='text-[16px] font-[700] mb-[10px]'>Audio Book Sample</p>

                                {/* sample audio player component */}
                                {
                                    audioUrl ?
                                        <div className='mt-2 flex items-center gap-2'>
                                            <SampleAudioComponent sampleAudio={audioUrl} />
                                            <BiTrash className='h-5 w-5 text-red-500 cursor-pointer' onClick={() => {
                                                setSelectedAudioFile(null)
                                                setAudioUrl(null)
                                            }} />
                                        </div>
                                        :
                                        <AudioSampleUploader id="story" placeholder='Select file to upload' onChange={handleAudioFileChange} />
                                }

                            </div>
                        </div>

                        {/* right */}
                        <div className="w-full">
                            <div className="flex justify-between items-center">
                                <p className='text-[16px] font-[700]'>Add a Chapter</p>
                                <BiPlusCircle className='h-[24px] w-[24px] text-black text-opacity-[80%] cursor-pointer' onClick={handleOpenAddChapter} />
                            </div>
                            <p className='text-[13px] font-[400] mt-[4px] text-[#A9B0B7]'>There are no chapters added in this story / book</p>


                            {/* added chapters */}
                            {
                                fetchingBookChapters ?
                                    <div className='flex justify-center items-center my-10'>
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 32, color: "black" }} spin />} />
                                    </div>
                                    :
                                    <>
                                        {
                                            bookChapters.length === 0 ?
                                                <div className='mt-10'>
                                                    <Empty description="No chapters added" />
                                                </div>
                                                :
                                                <div className="mt-5 w-full border-[1px] border-[#D7DBE1] rounded-[15px] p-[15px] shadow-sm">
                                                    <p className='text-[16px] font-[700] text-black text-opacity-[80%]'>Added Chapters</p>

                                                    {
                                                        bookChapters.map((chapter: any, i: number) => (
                                                            <div key={i}>
                                                                <SingleChapterComponent chapterInfo={chapter} reFetchChapters={() => setReFetchChapters(!reFetchChapters)} />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                        }

                                        {/* publish & draft buttons */}
                                        {
                                            bookChapters.length !== 0 &&
                                            <>
                                                <div className="mt-[42px] flex gap-[70px] items-center">
                                                    <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handleSaveAsDraft} disabled={isPublishing || savingDraft}>
                                                        {
                                                            savingDraft ?
                                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                                :
                                                                <p className='text-[16px] font-[700]'>Draft</p>
                                                        }
                                                    </button>

                                                    <button className='w-full h-[52px] rounded-[40px] bg-gradient-to-r from-[#2E11CD] to-[#BE24BA] text-white flex justify-center items-center disabled:cursor-not-allowed' onClick={handlePublish} disabled={isPublishing || savingDraft}>
                                                        {
                                                            isPublishing ?
                                                                <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />} />
                                                                :
                                                                <p className='text-[16px] font-[700]'>Publish</p>
                                                        }
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    </>
                            }

                        </div>
                    </div>
                </Spin>
            </Modal>


            {/* trigger chapter modal */}
            {
                openAddChapter && <AddStoryChapter isOpened={openAddChapter} handleCloseAddChapter={handleCloseAddChapter} bookId={tempBookData?.id} handleReFetch={() => setReFetchChapters(!reFetchChapters)} />
            }
        </>
    )
}

export default EditExistingStory
